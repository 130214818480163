/* global $ */
import { db } from "./../services/Firebase";
export default {

    jcarousel: (div) => {
        var $div = $(div)
        var jcarousel = $div.find('.jcarousel');
        var target_next;
        var target_prev;

        if(jcarousel.length === 0) return;

        jcarousel.on('jcarousel:reload jcarousel:create', function () {
            var carousel = $(this),
                width = carousel.innerWidth();
            var carousel_new = $('.jcarousel_new'),
                width_new = carousel.innerWidth();

            if (width >= 865) {
                width = width / 4;
                target_next = '+=4';
                target_prev = '-=4';
            } else if (width >= 620) {
                width = width / 2;
                target_next = '+=2';
                target_prev = '-=2';
            } else if (width >= 480) {
                width = width / 1;
                target_next = '+=1';
                target_prev = '-=1';
            }else if (width >= 350) {
                width = width / 1;
                target_next = '+=1';
                target_prev = '-=1';
            }

            carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
            carousel_new.jcarousel('items').css('width', Math.ceil(width_new / 2) + 'px');
        })
            .jcarousel({
                wrap: 'circular'
            });

        $div.find('.jcarousel-control-prev').jcarouselControl({
            target: target_prev
        });

        $div.find('.jcarousel-control-next').jcarouselControl({
            target: target_next
        });

        $div.find('.jcarousel-pagination')
            .on('jcarouselpagination:active', 'a', function() {
                $(this).addClass('active');
            })
            .on('jcarouselpagination:inactive', 'a', function() {
                $(this).removeClass('active');
            })
            .on('click', function(e) {
                e.preventDefault();
            })
            .jcarouselPagination({
                perPage: 1,
                item: function(page) {
                    return '<a href="#' + page + '">' + page + '</a>';
                }
            });

        $div.find('.jcarousel').jcarouselAutoscroll({
            interval: 5000,
            target: target_next,
            autostart: true
        });

        $div.imagesLoaded(function () {
            $(window).resize()
        });

    },

    orderPizza: ( toppings = {} ) => {
        // Order a pizza with some sweet toppings.
    },

    is_mobile_width: function () {
      return window.innerWidth <= 760;
    },

    api_get_object: function(route, callback, callback_error){
        let listing =  this.getStorage("obj_"+route);
        if(listing && callback) return callback(listing);
        try {
            db.ref(route).on("value", snapshot => {
                let listing = {};
                snapshot.forEach((snap) => {
                    listing[snap.key] = snap.val();
                });
                this.setStorage("obj_"+route, listing, 15)
                if(callback) callback(listing);
            });
        } catch (error) {
            if(callback_error) callback_error(error.message);
        }
    },

    api_get_all: function(route, callback, callback_error){
        let listing = this.getStorage("all_"+route);
        if(listing && callback) return callback(listing);
        try {
            db.ref(route).on("value", snapshot => {
                let listing = [];
                snapshot.forEach((snap) => {
                    listing.push(snap.val());
                });
                this.setStorage("all_"+route, listing, 15)
                if(callback) callback(listing);
            });
        } catch (error) {
            if(callback_error) callback_error(error.message);
        }
    },
    api_get_one: function(route, callback, callback_error){
        try {
            let one = this.getStorage("one_"+route);
            if(one && callback) return callback(one);
            db.ref(route).on("value", snapshot => {
                let one = {};
                snapshot.forEach((snap) => {
                    one = snap.val();
                    return false;
                });
                this.setStorage("one_"+route, one, 15)
                if(callback) callback(one);
            });
        } catch (error) {
            if(callback_error) callback_error(error.message);
        }
    },
    setStorage: function(key, value, minutes) {
        const item = {
            value: value,
            expiry: minutes <= 0 ? -1 : new Date().getTime() + (1000 * 60 * minutes)
        };
        // window.btoa -> encode
        // window.atob -> decode
        localStorage.setItem(key, JSON.stringify(item));
    },
    getStorage: function(key) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        if (item.expiry > 0 && new Date().getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    },
    shuffle: function(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    },
    limit_text: function(str, limit, end){
        limit = (limit)? limit : 100;
        end = (end)? end : '...';
        str = str.split(' ');

        if (str.length > limit) {
            var cutTolimit = str.slice(0, limit);
            return cutTolimit.join(' ') + ' ' + end;
        }

        return str.join(' ');
    },

    changeLanguage(){
        localStorage.setItem("language", window.lng === "fr" ? "en" : "fr");
        window.location.reload();
    }

}