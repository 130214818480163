import React, {Component} from 'react'
import LazyLoad from 'react-lazyload';
import helper from './../../helpers'

import Search from './Search'
import {Promo, PromoSingle, Promo2, PromoMobile} from './Promo'
import Testimony from './Testimony'
import Cars from './Cars'
import Motos from './Motos';

class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            is_mobile_width: helper.is_mobile_width()
        };
    }
    resize() {
        this.setState({is_mobile_width: helper.is_mobile_width()});
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    render() {
      return (
        <div id="content">
          <div className="container">
            <div className="row d-flex group-form-search group-form-search-home row-eq-height">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 container-form-search eq-height">
                <Search />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 container-promotion-new promotion-new-web eq-height">
                <Promo type="new"/>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 container-promotion-new promotion-new-web eq-height">
                <Promo type="used"/>
              </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="container-details-top"></div>
                </div>
            </div>
              {this.state.is_mobile_width ? "" : <LazyLoad height={200}>
                  <div className="row justify-content-between group-cars-bikes">
              <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                <PromoSingle type="new"/>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                <PromoSingle type="used"/>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 box-list-promotion promotion-new-web">
                <Promo2 type="service"/>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 box-list-promotion promotion-new-web">
                <Promo2 type="parts"/>
              </div>
            </div>
              </LazyLoad>}
            <div className="row group-cars-bikes-mobile">
                <div className="col-12 box-list-cars">
                    <PromoMobile/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="container-details-center"></div>
                </div>
            </div>
          </div>
          <div className="container-fluid bg-light">
              <LazyLoad height={200} offset={30}>
                <Testimony/>
              </LazyLoad>
          </div>
          <div className="container">
            <div className="row justify-content-between group-carousel-cars">
                <div className="col-12 box-info-cars">
                    <h2 className="text-center">Voitures d'occasion en vedettes</h2>
                    <LazyLoad height={200} offset={30}>
                        <Cars/>
                    </LazyLoad>

                </div>
            </div>

            <div className="row justify-content-between group-carousel-cars group-carousel-cars-web">
              <div className="col-12 box-info-cars">
                  <h2 className="text-center">Motos d'occasion en vedettes</h2>
                  <LazyLoad height={200} offset={30}>
                      <Motos/>
                  </LazyLoad>

              </div>
            </div>
          </div>

        </div>
      );
    }
}

export default Home;
