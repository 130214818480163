import React, {Component} from 'react'

class Messagetop extends Component{  
    render() {
      return (
        <div id="messageDIV">
	<a href="https://www.gabriel.ca/fr/service">
		<div className="messageBar" >
			Notre département de service est maintenant ouvert, prenez rendez-vous en ligne	</div>
	</a>
</div>
      );
    }
}

export default Messagetop;