import React, {Component} from 'react'
import helper from './../../helpers'

class Header extends Component{
    componentDidMount() {
 
    }
  
    render() {
      return (
        <header className="header">
    <div className="container">
        <nav className="navbar-expand-sm navbar-light bg-light">
            <div className="row justify-content-between group-container-header">
                <div className="col-12 col-md-5 col-lg-4 col-xl-3 logo">
                    <a href="/fr/index" title="">
                        <img src="/assets/group_logo.png" alt="" className="img-fluid"/>
                    </a>
                </div>

                <div className="menu-second-header menu-second-header-main">
                    <ul className="links-header-top">
                        <li className="first">
                            <a href="#" title="" className="link-menu">Autos</a>
                            <div className="content-dealer-menu container ser" style={{display:"none"}}>
                                <a href="#" title="" className="close-group-makes">&#120;</a>
                                <div className="row justify-content-between">
                                    <div className="col-12">
                                        <div className="group-content-cars">
                                            <h2 className="content-title-type">Autos</h2>
                                            <ul className="makelist">
                                                <li id="porsche" className="make">
                                                    <a href="/fr/widgets/porsche" title="">
                                                        <span className="makeName">Porsche</span>
                                                    </a>
                                                </li>
                                                <li id="audi" className="make">
                                                    <a href="/fr/widgets/audi" title="">
                                                        <span className="makeName">Audi</span>
                                                    </a>
                                                </li>
                                                <li id="lexus" className="make">
                                                    <a href="/fr/widgets/lexus" title="">
                                                        <span className="makeName">Lexus</span>
                                                    </a>
                                                </li>
                                                <li id="infiniti" className="make">
                                                    <a href="/fr/widgets/infiniti" title="">
                                                        <span className="makeName">Infiniti</span>
                                                    </a>
                                                </li>
                                                <li id="acura" className="make">
                                                    <a href="/fr/widgets/acura" title="">
                                                        <span className="makeName">Acura</span>
                                                    </a>
                                                </li>
                                                <li id="genesis" className="make">
                                                    <a href="https://www.genesisdelouest.ca/" title="" target="_blank">
                                                        <span className="makeName">Genesis</span>
                                                    </a>
                                                </li>												
                                                <li id="honda" className="make">
                                                    <a href="/fr/widgets/honda" title="">
                                                        <span className="makeName">Honda</span>
                                                    </a>
                                                </li>
                                                <li id="toyota" className="make">
                                                    <a href="/fr/widgets/toyota" title="">
                                                        <span className="makeName">Toyota</span>
                                                    </a>
                                                </li>
                                                <li id="nissan" className="make">
                                                    <a href="/fr/widgets/nissan" title="">
                                                        <span className="makeName">Nissan</span>
                                                    </a>
                                                </li>
                                                <li id="hyundai" className="make">
                                                    <a href="/fr/widgets/hyundai" title="">
                                                        <span className="makeName">Hyundai</span>
                                                    </a>
                                                </li>
                                                <li id="mazda" className="make">
                                                    <a href="/fr/widgets/mazda" title="">
                                                        <span className="makeName">Mazda</span>
                                                    </a>
                                                </li>
                                                <li id="volkswagen" className="make">
                                                    <a href="/fr/widgets/volkswagen" title="">
                                                        <span className="makeName">Volkswagen</span>
                                                    </a>
                                                </li>
                                                <li id="ford" className="make">
                                                    <a href="/fr/widgets/ford" title="">
                                                        <span className="makeName">Ford</span>
                                                    </a>
                                                </li>
                                                <li id="lincoln" className="make">
                                                    <a href="/fr/widgets/lincoln" title="">
                                                        <span className="makeName">Lincoln</span>
                                                    </a>
                                                </li>
                                                <li id="kia" className="make">
                                                    <a href="/fr/make/kia" title="">
                                                        <span className="makeName">Kia</span>
                                                    </a>
                                                </li>

                                                <li id="occasion" className="make">
                                                    <a href="https://www.occasiongabriel.com" title="" target="_blank">
                                                        <span className="makeName">Occasion</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="last">
                            <a href="#" title="" className="link-menu">Motos</a>
                            <div className="content-dealer-menu container ser" style={{display:"none"}}>
                                <a href="#" title="" className="close-group-makes-bikes">&#120;</a>
                                <div className="row justify-content-between">
                                    <div className="col-12">
                                        <div className="group-content-cars">
                                            <h2 className="content-title-type">Motos</h2>
                                            <ul className="makelist makelist_bikes">
                                                <li id="Harley-Davidson" className="make">
                                                    <a href="/fr/widgets-moto/harley-davidson" title="">
                                                        <span className="makeName">Harley-Davidson</span>
                                                    </a>
                                                </li>
                                                <li id="bmw" className="make">
                                                    <a href="/fr/widgets-moto/bmw" title="">
                                                        <span className="makeName">BMW</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                
                <div className="col-12 col-md-7 col-lg-8 col-xl-9 group-search group-search-main">
					 <div className="group-search group-search-main">
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbars-header" aria-controls="navbars-header" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                	
                    <div className="content-search-mobile">
                        <div className="group-search-mobile d-flex align-items-center">
                                                        <a href="#" title="" className="link-contact">Contact</a>
                                                        <div className="form-contact">
                                <a href="#" title="" className="close-form-contact">&#120;</a>
                                <div className="group-phone-form  d-flex align-items-center justify-content-between">
                                    <h4>Tél.: 514 377-7777</h4>
                                    <a href="tel:+15143777777" title="" className="btn-form-contact phoneLink">Appelle maintenant</a>
                                </div>
                                <h3>Veuillez remplir le formulaire ci-dessous : </h3>
                                <form id="search-form-contact" method="post" data-bvalidator-validate="" noValidate="novalidate">
                                    <input type="hidden" className="formType" name="data[type]" value="contact_us"/>
                                    <input type="hidden" name="data[url_car]" value="/fr/index"/>
                                    <input type="hidden" className="email-form" name="email-form" value="edwarperezg@gmail.com"/>
                                    <input type="hidden" className="page" name="page" value="home"/>
                                    <input type="hidden" className="dealer_vehicle" name="data[user_id]" value="1"/>
                                    <input type="hidden" value="fr" name="data[lang]"/>
                                    <input name="send" value="Save" type="hidden"/>
                                    <div className="form-group">
                                        <input type="text" className="form-control required" name="data[first_name]" placeholder="Prénom"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control required" name="data[last_name]" placeholder="Nom"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control required" name="data[phone]" placeholder="Tél."/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control required email" name="data[email]" placeholder="Courriel"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control required" name="data[comment]" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck" name="data[consent]" value="1"/>
                                            <label className="form-check-label" htmlFor="gridCheck">Vous consentez à ce que nous communiquions avec vous par voie électronique.</label>
                                        </div>
                                    </div>
                                    <div className="form-group toSubmit form-group-contact">
                                        <label>Entrez le code :</label>
                                        <div className="group-captcha-code">
                                            <img id="captcha_contact" src="" alt="CAPTCHA Image" style={{verticalAlign: "bottom"}} className="captcha"/>
                                            <input id="captcha_code_contact" type="text" name="captcha_code" size="10" maxLength="5" className="form-control required"/>
                                        </div>
                                                                                <label className="label_in_text_captcha">S'il vous pla&icirc;t entrer le code. (Le texte n'est pas sensible à la casse)</label>
                                        <a className="label_click_captcha label_click_captcha_design" id="click_captcha_general_inquiry" href="#" >
                                            (Cliquez ici si vous ne pouvez pas reconna&icirc;tre le code.)                                        </a>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-form-contact button-form-contact">Soumettre</button>
                                    <div className="text-message"></div>
                                </form>
                            </div>
                            <i className="fa fa-search fa-lg hidden-sm hidden-md hidden-lg fa-search-main" aria-hidden="true"></i>
                            <div className="searchFormMobile">
                                <form id="search-form-2" className="navbar-form navbar-left group-list-search" role="search" method="get" action="#" noValidate="noValidate">
                                    <div className="form-group">
                                        <input type="text" name="q" className="form-control searchInputMobile" placeholder="Recherche..." autoComplete="off"/>
                                        <button type="submit" className="searchSubmit" value=""><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </div>
                                </form>
                                <ul className="searchFormHeader"></ul>
                            </div>
                        </div>
                    </div>
						 
					<div className="d-flex align-items-center pull-right"  style={{float:"right"}}>	
					<div className="group-favorite group-favorite-header">
                    <ul>
                        <li style={{display:"none"}}>
                            <a href="/fr/cars/favorite" title="">
                                <span className="fa-stack">
                                    <i className="fa fa-star fa-stack-2x"></i>
                                    <strong className="nrFav fa-stack-1x star-text favNum">1</strong>
                                    <i className="fa fa-car icon-favorite-second" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                        <li style={{display:"none"}}>
                            <a href="/fr/motos/favorite" title="">
                                <span className="fa-stack">
                                    <i className="fa fa-star fa-stack-2x"></i>
                                    <strong className="mtFav fa-stack-1x star-text favNum">1</strong>
                                    <i className="fa fa-motorcycle icon-favorite-second" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>	
                    <div className="d-none d-md-inline-block group-content-phone text-right">
                        <a href="tel:+15143777777" title="" style={{color:"#000"}} className="phoneLink"><h4>514 377 7777</h4></a>
                    </div>

                    <div className="searchForm searchFormCustom">
                        <form id="mod-finder-searchform" action="#" method="get">
                            <input id="search_input" type="text" name="q" className="search searchInput" size="25" placeholder="Recherche..." autoComplete="off"/>
                            <button id="search_button" type="submit" ><i className="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                        <ul className="searchFormHeader searchFormHeaderWeb"></ul>
                    </div>

                    <div className="content-translate languaje-header-web">
                        <div className="language language-custom">
                            <a href="#!" onClick={helper.changeLanguage}>{window.lng == "fr" ? "EN" : "FR"}</a>
                            </div>
                    </div>
					</div>
                </div>
            </div>
				</div>

            <div className="row justify-content-between">
                <div className="col-12">
                    <div className="navbar-collapse collapse" id="navbars-header">
                        <div className="row justify-content-between content-navbar-header">
                            <div className="col-12 col-sm-12 col-md-9 group-makes-cars">
                                <div className="group-content-cars">
                                    <h2 className="content-title-type">Autos <i className="fa fa-angle-up rotate-icon-makes"></i></h2>
                                    <ul className="makelist">
                                        <li id="porsche" className="make">
                                            <a href="/fr/widgets/porsche" title="">
                                                <span className="makeName">Porsche</span>
                                            </a>
                                        </li>
                                        <li id="audi" className="make">
                                            <a href="/fr/widgets/audi" title="">
                                                <span className="makeName">Audi</span>
                                            </a>
                                        </li>
                                        <li id="lexus" className="make">
                                            <a href="/fr/widgets/lexus" title="">
                                                <span className="makeName">Lexus</span>
                                            </a>
                                        </li>
                                        <li id="infiniti" className="make">
                                            <a href="/fr/widgets/infiniti" title="">
                                                <span className="makeName">Infiniti</span>
                                            </a>
                                        </li>
                                        <li id="acura" className="make">
                                            <a href="/fr/widgets/acura" title="">
                                                <span className="makeName">Acura</span>
                                            </a>
                                        </li>
										  <li id="genesis" className="make">
                                            <a href="https://www.genesisdelouest.ca/" title="" target="_blank">
                                                <span className="makeName">Genesis</span>
                                            </a>
                                        </li>
                                        <li id="honda" className="make">
                                            <a href="/fr/widgets/honda" title="">
                                                <span className="makeName">Honda</span>
                                            </a>
                                        </li>
                                        <li id="toyota" className="make">
                                            <a href="/fr/widgets/toyota" title="">
                                                <span className="makeName">Toyota</span>
                                            </a>
                                        </li>
                                        <li id="nissan" className="make">
                                            <a href="/fr/widgets/nissan" title="">
                                                <span className="makeName">Nissan</span>
                                            </a>
                                        </li>
                                        <li id="hyundai" className="make">
                                            <a href="/fr/widgets/hyundai" title="">
                                                <span className="makeName">Hyundai</span>
                                            </a>
                                        </li>
                                        <li id="mazda" className="make">
                                            <a href="/fr/widgets/mazda" title="">
                                                <span className="makeName">Mazda</span>
                                            </a>
                                        </li>
                                        <li id="volkswagen" className="make">
                                            <a href="/fr/widgets/volkswagen" title="">
                                                <span className="makeName">Volkswagen</span>
                                            </a>
                                        </li>
                                        <li id="ford" className="make">
                                            <a href="/fr/widgets/ford" title="">
                                                <span className="makeName">Ford</span>
                                            </a>
                                        </li>
                                        <li id="lincoln" className="make">
                                            <a href="/fr/widgets/lincoln" title="">
                                                <span className="makeName">Lincoln</span>
                                            </a>
                                        </li>
                                        <li id="kia" className="make">
                                            <a href="/fr/make/kia" title="">
                                                <span className="makeName">Kia</span>
                                            </a>
                                        </li>


                                        <li id="occasion" className="make">
                                            <a href="https://www.occasiongabriel.com/fr/" title="" target="_blank">
                                                <span className="makeName">Occasion</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-3 group-makes-bikes">
                                <div className="group-content-cars">
                                    <h2 className="content-title-type">Motos <i className="fa fa-angle-up rotate-icon-makes"></i></h2>
                                    <ul className="makelist makelist_bikes">
                                        <li id="Harley-Davidson" className="make col-6 col-sm-12">
                                            <a href="/fr/widgets-moto/harley-davidson" title="">
                                                <span className="makeName">Harley-Davidson</span>
                                            </a>
                                        </li>
                                        <li id="bmw" className="make  col-6 col-sm-12">
                                            <a href="/fr/widgets-moto/bmw" title="">
                                                <span className="makeName">BMW</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="group-menu-header">
                                    <a href="#" title="" className="close">&#120;</a>
                                    <h2 className="content-title-type">Menu</h2>
                                    <ul>
                                        <li className="new-vehicle">
                                            <a href="#" title="" className="link-menu-parent">Neuf <i className="fa fa-caret-down" aria-hidden="true"></i></a>
                                            <div className="content-dealer-menu " style={{display:"none"}} >
                                                <div className="container cf">
                                                    <div className="content-submenu cf">
                                                        <div className="row" id="aboutMenu">
                                                            <div className="col-sm-6">
                                                                <a href="/fr/listing-new" title="" className="menuItem"><i className="fa fa-car" aria-hidden="true"></i> Voitures neuves</a>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <a href="/fr/listing-new-motos" title="" className="menuItem"><i className="fa fa-motorcycle" aria-hidden="true"></i> Motos neuves</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="occasion-vehicle">
                                            <a href="#" title="" className="link-menu-parent">D'occasion <i className="fa fa-caret-down" aria-hidden="true"></i></a>
                                            <div className="content-dealer-menu content-dealer-menu-second " style={{display:"none"}}>
                                                <div className="container cf">
                                                    <div className="content-submenu cf">
                                                        <div className="row" id="aboutMenu">
                                                            <div className="col-sm-6">
                                                                <a href="/fr/cars/listingPublic/all" title="" className="menuItem"><i className="fa fa-car" aria-hidden="true"></i> Voitures d'occasion</a>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <a href="/fr/motos/listingPublic/all" title="" className="menuItem"><i className="fa fa-motorcycle" aria-hidden="true"></i> Motos d'occasion</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
										<li>
                                            <a href="/fr/service" title="">Services</a>
                                        </li>
                                        <li>
                                            <a href="/fr/promotions" title="">Promotions</a>
                                        </li>
                                        <li>
                                            <a href="/fr/finance" title="">Finance</a>
                                        </li>
                                        <li>
                                            <a href="/fr/about-us" title="">À propos de nous</a>
                                        </li>
                                        <li>
                                            <a href="/fr/contact-us" title="">Contactez-nous</a>
                                        </li>
                                    </ul>
                                    <div className="content-translate">
                                        <div className="language">
                                            <a href="#!" onClick={helper.changeLanguage} className="last">{window.lng == "fr" ? "Anglais" : "Français"}</a>                               </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>
      );
    }
}
class HeaderSimple extends Component{
    componentDidMount() {

    }

    render() {
      return (
        <header className="header">
    <div className="container">
        <nav className="navbar-expand-sm navbar-light bg-light">
            <div className="row justify-content-between group-container-header">
                <div className="col-12 col-md-5 col-lg-4 col-xl-3 logo">
                    <a href="/fr/index" title="">
                        <img src="/assets/group_logo.png" alt="" className="img-fluid"/>
                    </a>
                </div>

                <div className="col-12 col-md-7 col-lg-8 col-xl-9 group-search group-search-main">
					 <div className="group-search group-search-main">
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbars-header" aria-controls="navbars-header" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="content-search-mobile">
                        <div className="group-search-mobile d-flex align-items-center">
                                                        <a href="#" title="" className="link-contact">Contact</a>
                                                        <div className="form-contact">
                                <a href="#" title="" className="close-form-contact">&#120;</a>
                                <div className="group-phone-form  d-flex align-items-center justify-content-between">
                                    <h4>Tél.: 514 377-7777</h4>
                                    <a href="tel:+15143777777" title="" className="btn-form-contact phoneLink">Appelle maintenant</a>
                                </div>
                                <h3>Veuillez remplir le formulaire ci-dessous : </h3>
                                <form id="search-form-contact" method="post" data-bvalidator-validate="" noValidate="novalidate">
                                    <input type="hidden" className="formType" name="data[type]" value="contact_us"/>
                                    <input type="hidden" name="data[url_car]" value="/fr/index"/>
                                    <input type="hidden" className="email-form" name="email-form" value="edwarperezg@gmail.com"/>
                                    <input type="hidden" className="page" name="page" value="home"/>
                                    <input type="hidden" className="dealer_vehicle" name="data[user_id]" value="1"/>
                                    <input type="hidden" value="fr" name="data[lang]"/>
                                    <input name="send" value="Save" type="hidden"/>
                                    <div className="form-group">
                                        <input type="text" className="form-control required" name="data[first_name]" placeholder="Prénom"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control required" name="data[last_name]" placeholder="Nom"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control required" name="data[phone]" placeholder="Tél."/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control required email" name="data[email]" placeholder="Courriel"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control required" name="data[comment]" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck" name="data[consent]" value="1"/>
                                            <label className="form-check-label" htmlFor="gridCheck">Vous consentez à ce que nous communiquions avec vous par voie électronique.</label>
                                        </div>
                                    </div>
                                    <div className="form-group toSubmit form-group-contact">
                                        <label>Entrez le code :</label>
                                        <div className="group-captcha-code">
                                            <img id="captcha_contact" src="" alt="CAPTCHA Image" style={{verticalAlign: "bottom"}} className="captcha"/>
                                            <input id="captcha_code_contact" type="text" name="captcha_code" size="10" maxLength="5" className="form-control required"/>
                                        </div>
                                                                                <label className="label_in_text_captcha">S'il vous pla&icirc;t entrer le code. (Le texte n'est pas sensible à la casse)</label>
                                        <a className="label_click_captcha label_click_captcha_design" id="click_captcha_general_inquiry" href="#" >
                                            (Cliquez ici si vous ne pouvez pas reconna&icirc;tre le code.)                                        </a>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-form-contact button-form-contact">Soumettre</button>
                                    <div className="text-message"></div>
                                </form>
                            </div>
                            <i className="fa fa-search fa-lg hidden-sm hidden-md hidden-lg fa-search-main" aria-hidden="true"></i>
                            <div className="searchFormMobile">
                                <form id="search-form-2" className="navbar-form navbar-left group-list-search" role="search" method="get" action="#" noValidate="noValidate">
                                    <div className="form-group">
                                        <input type="text" name="q" className="form-control searchInputMobile" placeholder="Recherche..." autoComplete="off"/>
                                        <button type="submit" className="searchSubmit" value=""><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </div>
                                </form>
                                <ul className="searchFormHeader"></ul>
                            </div>
                        </div>
                    </div>

					<div className="d-flex align-items-center pull-right"  style={{float:"right"}}>
					<div className="group-favorite group-favorite-header">
                    <ul>
                        <li style={{display:"none"}}>
                            <a href="/fr/cars/favorite" title="">
                                <span className="fa-stack">
                                    <i className="fa fa-star fa-stack-2x"></i>
                                    <strong className="nrFav fa-stack-1x star-text favNum">1</strong>
                                    <i className="fa fa-car icon-favorite-second" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                        <li style={{display:"none"}}>
                            <a href="/fr/motos/favorite" title="">
                                <span className="fa-stack">
                                    <i className="fa fa-star fa-stack-2x"></i>
                                    <strong className="mtFav fa-stack-1x star-text favNum">1</strong>
                                    <i className="fa fa-motorcycle icon-favorite-second" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                    <div className="d-none d-md-inline-block group-content-phone text-right">
                        <a href="tel:+15143777777" title="" style={{color:"#000"}} className="phoneLink"><h4>514 377 7777</h4></a>
                    </div>

                    <div className="searchForm searchFormCustom">
                        <form id="mod-finder-searchform" action="#" method="get">
                            <input id="search_input" type="text" name="q" className="search searchInput" size="25" placeholder="Recherche..." autoComplete="off"/>
                            <button id="search_button" type="submit" ><i className="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                        <ul className="searchFormHeader searchFormHeaderWeb"></ul>
                    </div>

                    <div className="content-translate languaje-header-web">
                        <div className="language language-custom">
                            <a href="#!" onClick={helper.changeLanguage}>{window.lng == "fr" ? "EN" : "FR"}</a>
                        </div>
                    </div>
					</div>
                </div>
            </div>
				</div>

            <div className="row justify-content-between">
                <div className="col-12">
                    <div className="navbar-collapse collapse" id="navbars-header">
                        <div className="row justify-content-between content-navbar-header">

                            <div className="col-12">
                                <div className="group-menu-header">
                                    <a href="#" title="" className="close">&#120;</a>
                                    <h2 className="content-title-type">Menu</h2>
                                    <ul>
                                        <li className="new-vehicle">
                                            <a href="#" title="" className="link-menu-parent">Neuf <i className="fa fa-caret-down" aria-hidden="true"></i></a>
                                            <div className="content-dealer-menu " style={{display:"none"}} >
                                                <div className="container cf">
                                                    <div className="content-submenu cf">
                                                        <div className="row" id="aboutMenu">
                                                            <div className="col-sm-6">
                                                                <a href="/fr/listing-new" title="" className="menuItem"><i className="fa fa-car" aria-hidden="true"></i> Voitures neuves</a>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <a href="/fr/listing-new-motos" title="" className="menuItem"><i className="fa fa-motorcycle" aria-hidden="true"></i> Motos neuves</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="occasion-vehicle">
                                            <a href="#" title="" className="link-menu-parent">D'occasion <i className="fa fa-caret-down" aria-hidden="true"></i></a>
                                            <div className="content-dealer-menu content-dealer-menu-second " style={{display:"none"}}>
                                                <div className="container cf">
                                                    <div className="content-submenu cf">
                                                        <div className="row" id="aboutMenu">
                                                            <div className="col-sm-6">
                                                                <a href="/fr/cars/listingPublic/all" title="" className="menuItem"><i className="fa fa-car" aria-hidden="true"></i> Voitures d'occasion</a>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <a href="/fr/motos/listingPublic/all" title="" className="menuItem"><i className="fa fa-motorcycle" aria-hidden="true"></i> Motos d'occasion</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
										<li>
                                            <a href="/fr/service" title="">Services</a>
                                        </li>
                                        <li>
                                            <a href="/fr/promotions" title="">Promotions</a>
                                        </li>
                                        <li>
                                            <a href="/fr/finance" title="">Finance</a>
                                        </li>
                                        <li>
                                            <a href="/fr/about-us" title="">À propos de nous</a>
                                        </li>
                                        <li>
                                            <a href="/fr/contact-us" title="">Contactez-nous</a>
                                        </li>
                                    </ul>
                                    <div className="content-translate">
                                        <div className="language">
                                            <a href="/translations/setLang/en">Anglais</a><a href="/translations/setLang/fr" className="last">Français</a>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>
      );
    }
}

export {Header, HeaderSimple};