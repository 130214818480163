/* global $, Swiper */
import React, {Component} from 'react'
import { db } from "./../../services/Firebase";
import helper from './../../helpers'
//import {  useFirestoreDocData, useFirestoreCollection,   useFirestore, SuspenseWithPerf  } from 'reactfire';


function SlidePromo(props){
    const slides = props.listing.map((row) =>
        <div className="swiper-slide" key={row.id}>
            <div className="list-promotion">
                <div className="img-intro-left">
                    <a href={row.url} title={row.name}>
                        <img src={row.image} alt={row.name} className="img-responsive"/>
                    </a>
                </div>
                <div className="intro-right">
                    <h3>
                        <a className="news_events_title" href={row.url}>{row.name}</a>
                    </h3>
                    <p>
                        {row.summary}
                    </p>
                    <a className="readmore" href={row.url}>+ </a>
                </div>
            </div>
        </div>
    );
    return <div className="swiper-wrapper">{slides}</div>;
}

class Promo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "new",
            listing: [],
            error: null,
            loading: false
        };
    }
    runScripts(){
        var div = "swiper" + Math.floor(Math.random() * 10001);
        $(this.refs.promo_div).attr("id", div);

        var swiper_cube = new Swiper('#'+div, {
            pagination: '.swiper-pagination',
            effect: 'cube',
            grabCursor: true,
            speed: 1000,
            loop: true,
            autoplay: 5000,
            cube: {
                shadow: false,
                slideShadows: false,
                shadowOffset: 0,
                shadowScale: 0
            }
        });
    }

    componentDidUpdate(prevProps, prevState){
            this.runScripts();
    }

    componentDidMount() {
        this.setState({ error: null, loading: true });
        let that = this;

        helper.api_get_all("home/promo_"+this.state.type+"_fr", function(listing){
            that.setState({ listing: listing,  loading: false});
        }, function(message){
            that.setState({ error: message, listing: false });
        });
    }
    render() {
      return (
        <div className="swiper-container-cube" ref="promo_div">
            {this.state.loading ? <div className="loading-box">
                <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> : ""}
            {this.state.listing.length > 0 ? <SlidePromo listing={this.state.listing}/> : ""}
        </div>
      );
    }
}


class Promo2 extends Component{
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "new",
            listing: [],
            error: null,
            loading: false
        };
    }
    runScripts(){
        var div = "swiper" + Math.floor(Math.random() * 10001);
        $(this.refs.promo_div).attr("id", div);
        var swiper = new Swiper('#'+div, {
            effect: 'flip',
            speed: 1000,
            loop: true,
            grabCursor: true,
            autoplay: 5000,
            autoplayDisableOnInteraction: false
        });
    }

    componentDidUpdate(prevProps, prevState){
        this.runScripts();
    }
    componentDidMount() {
        this.setState({ error: null, loading: true });
        let that = this;

        helper.api_get_all("home/promo_"+this.state.type+"_fr", function(listing){
            that.setState({ listing: listing,  loading: false});
        }, function(message){
            that.setState({ error: message, listing: false });
        });
    }
    render() {
      return (
        <div className="swiper-container" ref="promo_div">
            {this.state.loading ? <div className="loading-box">
                <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> : ""}
            {this.state.listing.length > 0 ? <SlidePromo listing={this.state.listing}/> : ""}
        </div>
      );
    }
}


class PromoSingle extends Component{
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "new",
            item: {},
            error: null,
            loading: false
        };
    }
    componentDidMount() {
        this.setState({ error: null, loading: true });
        let that = this;
        helper.api_get_one("home/result_page_fr", function(item){
            that.setState({ item: item, loading: false });
        }, function(message){
            that.setState({ error: message, listing: false });
        })
    }
    render() {
        return (
            <div className=" box-list-cars">
                {this.state.loading ? <div className="loading-box">
                    <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> : ""}
                <div style={this.state.loading ? {display: "none"} : {display: "block"}}>
                    <img src={this.state.item['image_'+this.state.type+'_car_fr']} alt="" className="img-responsive"/>
                    <ul className="group-links-box-web">
                        <li>
                            <a href="https://www.gabriel.ca/fr/listing-new" title="">Neuf</a>
                        </li>
                        <li className="last">
                            <a href="https://www.gabriel.ca/fr/cars/listingPublic/all" title="">D'occasion</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}


class PromoMobile extends Component{
    componentDidMount() {

    }
    render() {
      return (
        <ul className="group-links-box">
            <li>
                <div className="details-links-box" style={{display:"none"}}>
                    <a href="https://www.gabriel.ca/fr/listing-new" title=""><i className="fa fa-angle-right" aria-hidden="true"></i>Nouvelles voitures</a>
                    <a href="https://www.gabriel.ca/fr/listing-new-motos" title=""><i className="fa fa-angle-right" aria-hidden="true"></i>Motos neuves</a>
                </div>
                <a href="#" title="" className="links-auto-bike">Neuf</a>
            </li>
            <li className="last">
                <div className="details-links-box" style={{display:"none"}}>
                    <a href="https://www.gabriel.ca/fr/cars/listingPublic/all" title=""><i className="fa fa-angle-right" aria-hidden="true"></i>Voitures d'occasion</a>
                    <a href="https://www.gabriel.ca/fr/motos/listingPublic/all" title=""><i className="fa fa-angle-right" aria-hidden="true"></i>Motos d'occasion</a>
                </div>
                <a href="#" title="" className="links-auto-bike">Véhicules d'occasion</a>
            </li>
        </ul>
      );
    }
}

export {Promo, PromoSingle, Promo2, PromoMobile};
