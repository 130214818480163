import React from 'react';
import './../assets/css/App.css';

import Messagetop from '../components/Layout/Messagetop'
import {Header, HeaderSimple} from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import Home from '../components/Home'
import ModelCarView from '../components/ModelCarView'

import { BrowserRouter, Route} from "react-router-dom";

window.lng = localStorage.getItem("language") || "fr";

function App() {
  return (
    <div id="wrapper" className="App">
        <Messagetop/>

        <BrowserRouter>
            <Route exact path={["/", "/home", "/:lang/index"]}>
                <Header/>
                <Home />
            </Route>
            <Route path="/fr/widgets/:make">
                <HeaderSimple/>
                <ModelCarView  />
            </Route>
        </BrowserRouter>

      <Footer/>
    </div>
  );
}

export default App;
